import { animate } from "motion";

export default function backButton() {
	return {
		init() {
			if (!document.startViewTransition) {
				this.initBackButton();
			}
		},
		initBackButton() {
			const previousPage = document.referrer;
			const homeLink = document.querySelector("[rel='home']").href;
			if (previousPage === homeLink) {
				const leftDistance = this.$el.getBoundingClientRect().left;
				const marginsPlusSize = this.$el.offsetWidth + leftDistance;
				const initalDistance = window.innerWidth - marginsPlusSize;
				animate(
					this.$el,
					{
						left: [`${initalDistance}px`, `${leftDistance}px`],
						rotate: "-360deg",
					},
					{
						duration: 1,
						easing: "ease-in-out",
					}
				);
			}
		},
	};
}
