import Splitting from "splitting";
import { stagger, timeline, scroll } from "motion";

export default function homeText() {
	return {
		init() {
			this.animateWords();
		},
		animateWords() {
			const chars = Splitting({ target: this.$refs.text, by: "chars" });
			const lastChar = chars[0].chars.slice(-1);
			const lastCharIsFullStop = lastChar[0].textContent === ".";
			const allChars = lastCharIsFullStop
				? chars[0].chars.slice(0, -1)
				: chars[0].chars;
			const allParagraphs = this.$refs.text.querySelectorAll("p");

			// check if last char is a full stop
			if (lastCharIsFullStop) {
				lastChar[0].style.display = "inline-block";
				const sequenceWithFullStop = [
					[
						allParagraphs,
						{ opacity: [0, 1], x: ["-100%", "0%"] },
						{ duration: 5, delay: stagger(1) },
					],
					[
						allChars,
						{ opacity: [0.1, 1] },
						{ duration: 0.3, delay: stagger(0.3) },
					],
					[
						lastChar,
						{ y: ["100vh", "0vh"], opacity: [0.1, 1] },
						{
							duration: 10,
							easing: "linear(0, 0.006, 0.025 2.8%, 0.101 6.1%, 0.539 18.9%, 0.721 25.3%, 0.849 31.5%,0.937 38.1%, 0.968 41.8%, 0.991 45.7%, 1.006 50.1%, 1.015 55%, 1.017 63.9%,1.001)",
						},
					],
					// [
					// 	allParagraphs,
					// 	{ y: ["0vh", "-50vh"] },
					// 	{
					// 		duration: 5,
					// 		delay: stagger(1),
					// 	},
					// ],
					// [
					// 	allParagraphs,
					// 	{ opacity: [1, 0] },
					// 	{
					// 		duration: 5,
					// 		delay: stagger(1, { from: "last" }),
					// 		at: "<",
					// 	},
					// ],
				];
				scroll(timeline(sequenceWithFullStop), {
					target: this.$root,
					offset: ["start center", "end end"],
				});
			} else {
				const sequence = [
					[
						allParagraphs,
						{ opacity: [0, 1], x: ["-100%", "0%"] },
						{ duration: 2, delay: stagger(1) },
					],
					[
						allChars,
						{ opacity: [0.1, 1] },
						{ duration: 0.3, delay: stagger(0.3) },
					],
					// [
					// 	allParagraphs,
					// 	{ y: ["0vh", "-50vh"] },
					// 	{
					// 		duration: 5,
					// 		delay: stagger(1),
					// 	},
					// ],
					// [
					// 	allParagraphs,
					// 	{ opacity: [1, 0] },
					// 	{
					// 		duration: 5,
					// 		delay: stagger(1, { from: "last" }),
					// 		at: "<",
					// 	},
					// ],
				];
				scroll(timeline(sequence), {
					target: this.$root,
					offset: ["start center", "end end"],
				});
			}
		},
	};
}
