import { scroll, animate, stagger, timeline, spring, resize } from "motion";

export default function logoAnimation() {
	return {
		logoSize: 224,
		logoHeight: 96,
		init() {
			this.animateLogo();
			this.resizeChecks();
			this.animateOnScroll();
		},
		resizeChecks() {
			if (window.innerWidth < 768 && this.logoSize != 176) {
				this.logoSize = 176;
				this.logoHeight = 83;
			} else if (this.logoSize != 224) {
				this.logoSize = 224;
				this.logoHeight = 96;
			}
		},
		animateOnScroll() {
			scroll(
				animate(this.$refs.scrollDown, {
					opacity: [1, 0],
				}),
				{
					target: this.$root,
					offset: ["1 1", "1 0.8"],
				}
			);
			scroll(
				animate(this.$refs.logo, {
					width: ["83%", `${this.logoSize}px`, null],
				}),
				{
					target: this.$root,
					offset: ["end center", "end start"],
				}
			);
			scroll(
				animate(this.$refs.logoWrapper, {
					height: ["100vh", `${this.logoHeight}px`, null],
				}),
				{
					target: this.$root,
					offset: ["end center", "end start"],
				}
			);
			scroll(
				animate(this.$refs.logoBox, {
					width: ["100%", `${Math.abs(this.logoSize + 20)}px`, null],
				}),
				{
					target: this.$root,
					offset: ["end center", "end start"],
				}
			);
		},
		animateLogo() {
			const lineOneElements = this.$refs.lineOne.querySelectorAll("path");
			const lineTwoElements = this.$refs.lineTwo.querySelectorAll("path");

			const sequence1 = [
				[this.$refs.outline, { opacity: [0, 1] }, { duration: 0.5 }],
				[
					this.$refs.outline,
					{ strokeDasharray: ["0,1", "1,1"] },
					{ duration: 1, at: "<" },
				],
				[
					this.$refs.face,
					{ opacity: [0, 1] },
					{ duration: 0.5, at: "-0.5" },
				],
				[
					this.$refs.face,
					{ strokeDasharray: ["0,1", "1,1"] },
					{ duration: 6, at: "<" },
				],
				[
					this.$refs.eye,
					{ x: ["20px", "0px"], opacity: [0, 1] },
					{ duration: 1, at: "<" },
				],
			];
			const sequence2 = [
				[
					lineOneElements,
					{ opacity: [0, 1], y: ["-100%", "0%"] },
					{
						duration: 0.2,
						delay: stagger(0.15),
						easing: spring({ damping: 15 }),
					},
				],
				[
					lineTwoElements,
					{ opacity: [0, 1], y: ["100%", "0%"] },
					{
						duration: 0.2,
						delay: stagger(0.15),
						easing: spring({ damping: 15 }),
						at: "<",
					},
				],
			];
			const sequence3 = [
				[this.$refs.logo, { scale: [0.5, 1] }, { duration: 2.5 }],
			];
			timeline(sequence1);
			timeline(sequence2);
			timeline(sequence3);
		},
	};
}
