import { stagger, animate, timeline, scroll } from "motion";
import Splitting from "splitting";

export default function home() {
	const menuTextElements = this.$root.querySelectorAll("[data-text]");
	const bodyChangingElementsToCheck =
		document.querySelectorAll("[data-colour]");
	const menuChangingElementsToCheck =
		document.querySelectorAll("[data-menu-colour]");
	return {
		bodyClasses: "bg-oasisNight text-palmGreen-100",
		menuClasses: "bg-palmGreen-100 text-oasisNight",
		menuSecondaryClasses:
			"bg-oasisNight text-palmGreen-100 border-palmGreen-100 hover:bg-palmGreen-100 hover:text-oasisNight",
		menuText: "Menu",
		menuOpen: false,
		init() {
			this.splitMenuText();
			this.checkColours();
			this.initMenuProgress();
			this.$watch("menuText", (newValue, oldValue) => {
				if (newValue !== oldValue) {
					const newEl = this.$root.querySelector(
						`[data-text="${newValue}"]`
					);
					const oldEl = this.$root.querySelector(
						`[data-text="${oldValue}"]`
					);
					this.animateMenuText(oldEl, newEl);
				}
			});
		},
		animateMenuText(oldEl, newEl) {
			const oldLetters = oldEl ? oldEl.querySelectorAll(".char") : [];
			const newLetters = newEl ? newEl.querySelectorAll(".char") : [];
			newEl.classList.remove("hidden");
			newEl.classList.add("flex");
			timeline([
				[
					newLetters,
					{ opacity: [0, 1], y: ["100%", "0"] },
					{
						duration: 0.5,
						delay: stagger(0.1),
						easing: "cubic-bezier(.5, -.9, .1, 1.5)",
					},
				],
				[
					oldLetters,
					{ opacity: [1, 0], y: ["0", "-100%"] },
					{
						duration: 0.5,
						delay: stagger(0.1),
						easing: "cubic-bezier(.5, -.9, .1, 1.5)",
						at: "<",
					},
				],
			]);
		},
		splitMenuText() {
			menuTextElements.forEach((element) => {
				const letters = Splitting({ target: element, by: "chars" });
			});
		},
		checkMenuText() {
			menuChangingElementsToCheck.forEach((element) => {
				const isHalfVisible = this.checkVisibility(element);
				if (
					isHalfVisible &&
					this.menuText != element.dataset.menuText
				) {
					this.menuText = element.dataset.menuText;
				}
			});
		},
		checkColours() {
			menuChangingElementsToCheck.forEach((element) => {
				const isHalfVisible = this.checkVisibility(element);
				if (
					isHalfVisible &&
					this.menuClasses != element.dataset.menuColour
				) {
					this.$store.themeColor = element.dataset.theme;
					this.menuText = element.dataset.menuText;
					this.menuClasses = element.dataset.menuColour;
					this.menuSecondaryClasses = element.dataset.menuSecondary;
					if (this.menuOpen) {
						this.closeMenu();
					}
				}
			});
			bodyChangingElementsToCheck.forEach((element) => {
				const isHalfVisible = this.checkVisibility(element);
				if (
					isHalfVisible &&
					this.bodyClasses != element.dataset.colour
				) {
					this.bodyClasses = element.dataset.colour;
				}
			});
		},
		initMenuProgress() {
			menuChangingElementsToCheck.forEach((element, index, arr) => {
				// check if last element in loop

				const menuText = element.dataset.menuText.toLowerCase();
				const progressElement = this.$refs.progressSvg.querySelector(
					`circle.${menuText}`
				);
				if (!progressElement) return;
				scroll(
					animate(progressElement, {
						strokeDasharray: ["0,1", "1,1"],
					}),
					{
						target: element,
						offset:
							index === arr.length - 1
								? ["start center", "end end"]
								: ["start center", "end center"],
					}
				);
			});
		},
		checkVisibility(element) {
			// check if the element fills more than half of the viewport
			const rect = element.getBoundingClientRect();
			const windowHeight =
				window.innerHeight || document.documentElement.clientHeight;
			const isHalfVisible =
				rect.top < windowHeight / 2 && rect.bottom > windowHeight / 2;
			return isHalfVisible;
		},
		toggleMenu() {
			if (this.menuOpen) {
				const isMobile =
					/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
						navigator.userAgent
					);
				this.hideMenuItems();
				if (isMobile) {
					this.checkMenuText();
				} else {
					this.menuText = "Menu";
				}
			} else {
				this.menuText = "Close";
				this.showMenuItems();
			}
			this.menuOpen = !this.menuOpen;
		},
		closeMenu() {
			if (this.menuOpen) {
				this.hideMenuItems();
				this.menuOpen = false;
			}
		},
		mouseEnter() {
			if (!this.menuOpen) {
				this.menuText = "Menu";
			}
		},
		mouseLeave() {
			if (!this.menuOpen) {
				this.checkMenuText();
			}
		},
		showMenuItems() {
			const menuItems = this.$refs.menu.querySelectorAll("li");
			const reversedMenuItems = Array.from(menuItems).reverse();
			reversedMenuItems.forEach((item, index) => {
				item.classList.remove("hidden");
				// const delay = this.menuOpen ? index * 50 : (menuItems.length - index) * 50;
				const initalSpace = window.innerWidth > 768 ? 120 : 100;
				const offset = index * 80;
				animate(
					item,
					{
						bottom: `${initalSpace + offset}px`,
					},
					{
						easing: "ease-out",
						duration: 0.3,
						easing: "linear(0, 0.006, 0.025 2.8%, 0.101 6.1%, 0.539 18.9%, 0.721 25.3%, 0.849 31.5%,0.937 38.1%, 0.968 41.8%, 0.991 45.7%, 1.006 50.1%, 1.015 55%, 1.017 63.9%,1.001)",
					}
				);
			});
		},
		hideMenuItems() {
			const menuItems = this.$refs.menu.querySelectorAll("li");
			const reversedMenuItems = Array.from(menuItems).reverse();
			const bottomValue = window.innerWidth < 768 ? "20px" : "40px";
			reversedMenuItems.forEach((item) => {
				animate(
					item,
					{
						bottom: bottomValue,
					},
					{
						easing: "ease-in",
						duration: 0.3,
						easing: "linear(0, 0.006, 0.025 2.8%, 0.101 6.1%, 0.539 18.9%, 0.721 25.3%, 0.849 31.5%,0.937 38.1%, 0.968 41.8%, 0.991 45.7%, 1.006 50.1%, 1.015 55%, 1.017 63.9%,1.001)",
					}
				).finished.then(() => {
					item.classList.add("hidden");
				});
			});
		},
		// scrollTo(element) {
		// 	const el = document.getElementById(element);
		// 	const hashval = this.$el.getAttribute("href");
		// 	el.scrollIntoView({ behavior: "smooth" });
		// 	history.pushState(null, null, hashval);
		// },
	};
}
