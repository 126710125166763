import { stagger, timeline, scroll } from "motion";

export default function svgReveal(target, offset = ["start start", "end end"]) {
	return {
		desktop: false,
		init() {
			this.animateSvg(target, offset);
		},
		animateSvg(target, offset) {
			const targetElement = target
				? document.querySelector(target)
				: this.$root;
			const sequence = [
				[
					this.$refs.background,
					{ opacity: [0, 1], scale: [0, 1], rotate: [180, 0] },
					{ duration: 3 },
				],
				[
					this.$refs.burgers.querySelectorAll("path"),
					{ strokeDasharray: ["0,1", "1,1"], opacity: [0, 1] },
					{
						duration: 1,
						delay: stagger(0.5),
						at: "<",
					},
				],
				[
					this.$refs.circles.querySelectorAll("circle"),
					{ opacity: [0, 1] },
					{ duration: 1, delay: stagger(0.5), at: "<" },
				],
				[
					this.$refs.mainLines.querySelectorAll("path"),
					{
						strokeDasharray: ["0,1", "1,1"],
						opacity: [0, 1],
					},
					{
						duration: 1,
						at: "<",
						delay: stagger(0.25, { from: "last" }),
					},
				],
				[
					this.$refs.eyes.querySelectorAll("path"),
					{ opacity: [0, 1] },
					{ duration: 2, at: "0.25" },
				],
				[
					this.$refs.stroke,
					{ strokeDasharray: ["0,1", "1,1"], opacity: [0, 1] },
					{ duration: 10 },
				],
			];
			scroll(timeline(sequence), {
				target: targetElement,
				offset: offset,
			});
		},
	};
}
