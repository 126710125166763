import { scroll, animate, stagger, timeline } from "motion";

export default function serviceIndex() {
	const textRefElements = this.$root.querySelectorAll("[data-ref]");
	const illustrationRefElements = this.$root.querySelectorAll(
		"[data-ref-illustration]"
	);
	return {
		currentTextRef: "",
		currentIllustrationRef: "",
		init() {
			this.ensureElsStartWithCorrectState();
			this.checkRefs();
			window.addEventListener("scroll", () => {
				this.checkRefs();
			});

			if (window.innerWidth > 768) {
				textRefElements.forEach((targetElement) => {
					const elToAnimate = this.$root.querySelector(
						`[data-text="text-${targetElement.dataset.ref}"]`
					);
					scroll(
						animate(elToAnimate, {
							y: ["10vh", "-10vh"],
						}),
						{
							target: targetElement,
							offset: ["start start", "end end"],
						}
					);
				});
			}

			this.$watch("currentTextRef", (newValue, oldValue) => {
				if (newValue !== oldValue) {
					const newEl = this.$root.querySelector(
						`[data-text="text-${newValue}"]`
					);
					const oldEl = this.$root.querySelector(
						`[data-text="text-${oldValue}"]`
					);
					this.animateText(oldEl, newEl);
				}
			});
			this.$watch("currentIllustrationRef", (newValue, oldValue) => {
				if (newValue !== oldValue) {
					const newEl = this.$root.querySelector(
						`[data-illustration="illustration-${newValue}"]`
					);
					const oldEl = this.$root.querySelector(
						`[data-illustration="illustration-${oldValue}"]`
					);
					this.animateIllustration(oldEl, newEl);
				}
			});
		},
		ensureElsStartWithCorrectState() {
			const elementsToAnimate = this.$root.querySelectorAll(
				"h2, h3, p, ul, ol, .marker"
			);
			elementsToAnimate.forEach((el) => {
				el.style.opacity = 0;
				el.style.transform = "translateY(1rem)";
			});

			const backgrounds = this.$root.querySelectorAll(
				"[x-ref='background']"
			);
			backgrounds.forEach((el) => {
				el.style.opacity = 0;
				el.style.transform = "scale(0)";
				el.style.transform = "rotate(180deg)";
			});

			const burgers = this.$root.querySelectorAll(
				"[x-ref='burgers'] path"
			);
			burgers.forEach((el) => {
				el.style.strokeDasharray = "0,1";
				el.style.opacity = 0;
			});

			const circles = this.$root.querySelectorAll(
				"[x-ref='circles'] circle"
			);
			circles.forEach((el) => {
				el.style.opacity = 0;
			});

			const mainLines = this.$root.querySelectorAll(
				"[x-ref='mainLines'] path"
			);
			mainLines.forEach((el) => {
				el.style.strokeDasharray = "0,1";
				el.style.opacity = 0;
			});

			const eyes = this.$root.querySelectorAll("[x-ref='eyes'] path");
			eyes.forEach((el) => {
				el.style.opacity = 0;
			});

			const strokes = this.$root.querySelectorAll("[x-ref='stroke']");
			strokes.forEach((stroke) => {
				stroke.style.strokeDasharray = "0,1";
				stroke.style.opacity = 0;
			});
		},
		animateIllustration(oldEl, newEl) {
			const newBackground = newEl
				? newEl.querySelector("[x-ref='background']")
				: [];
			const oldBackground = oldEl
				? oldEl.querySelector("[x-ref='background']")
				: [];
			const newBurgers = newEl
				? newEl
						.querySelector("[x-ref='burgers']")
						.querySelectorAll("path")
				: [];
			const oldBurgers = oldEl
				? oldEl
						.querySelector("[x-ref='burgers']")
						.querySelectorAll("path")
				: [];
			const newCircles = newEl
				? newEl
						.querySelector("[x-ref='circles']")
						.querySelectorAll("circle")
				: [];
			const oldCircles = oldEl
				? oldEl
						.querySelector("[x-ref='circles']")
						.querySelectorAll("circle")
				: [];
			const newMainLines = newEl
				? newEl
						.querySelector("[x-ref='mainLines']")
						.querySelectorAll("path")
				: [];
			const oldMainLines = oldEl
				? oldEl
						.querySelector("[x-ref='mainLines']")
						.querySelectorAll("path")
				: [];

			const newEyes = newEl
				? newEl.querySelector("[x-ref='eyes']").querySelectorAll("path")
				: [];
			const oldEyes = oldEl
				? oldEl.querySelector("[x-ref='eyes']").querySelectorAll("path")
				: [];
			const newStroke = newEl
				? newEl.querySelector("[x-ref='stroke']")
				: [];
			const oldStroke = oldEl
				? oldEl.querySelector("[x-ref='stroke']")
				: [];

			const sequence = [
				[
					oldStroke,
					{ strokeDasharray: ["1,1", "0,1"], opacity: [1, 0] },
					{ duration: 0.5 },
				],
				[oldEyes, { opacity: [1, 0] }, { duration: 0.1 }],
				[
					oldMainLines,
					{
						strokeDasharray: ["1,1", "0,1"],
						opacity: [1, 0],
					},
					{
						duration: 0.5,
						at: "<",
					},
				],
				[oldCircles, { opacity: [1, 0] }, { duration: 0.5, at: "<" }],
				[
					oldBurgers,
					{ strokeDasharray: ["1,1", "0,1"], opacity: [1, 0] },
					{
						duration: 0.5,
						at: "<",
					},
				],
				[
					oldBackground,
					{ opacity: [1, 0], scale: [1, 0] },
					{ duration: 0.3 },
				],

				[oldEl, { opacity: [1, 0] }, { duration: 0.1 }],
				[newEl, { opacity: [0, 1] }, { duration: 0.1, at: "<" }],
				[
					newBackground,
					{ opacity: [0, 1], scale: [0, 1], rotate: [180, 0] },
					{ duration: 0.3 },
				],
				[
					newBurgers,
					{ strokeDasharray: ["0,1", "1,1"], opacity: [0, 1] },
					{
						duration: 0.1,
						delay: stagger(0.05),
						at: "<",
					},
				],
				[
					newCircles,
					{ opacity: [0, 1] },
					{ duration: 0.1, delay: stagger(0.05), at: "<" },
				],
				[
					newMainLines,
					{
						strokeDasharray: ["0,1", "1,1"],
						opacity: [0, 1],
					},
					{
						duration: 0.1,
						at: "<",
						delay: stagger(0.05, { from: "last" }),
					},
				],
				[newEyes, { opacity: [0, 1] }, { duration: 0.1 }],
				[
					newStroke,
					{ strokeDasharray: ["0,1", "1,1"], opacity: [0, 1] },
					{ duration: 0.5, at: "<" },
				],
			];
			timeline(sequence);
		},
		animateText(oldEl, newEl) {
			const oldElsToAnimate = oldEl
				? oldEl.querySelectorAll("h2, h3, p, ul, ol, .marker")
				: [];
			const newElsToAnimate = newEl
				? newEl.querySelectorAll("h2, h3, p, ul, ol, .marker")
				: [];
			const sequence = [
				[
					oldElsToAnimate,
					{ opacity: ["1", "0"] },
					{ duration: 0.3, delay: stagger(0.15) },
				],
				[
					newElsToAnimate,
					{ opacity: ["0", "1"] },
					{ duration: 0.3, delay: stagger(0.15) },
				],
			];
			timeline(sequence);
		},
		checkRefs() {
			// check if the root element is visible
			const isRootVisible = this.checkVisibility(this.$root);
			if (!isRootVisible && this.currentTextRef != "") {
				this.currentTextRef = "";
				this.currentIllustrationRef = "";
			}
			// check if the text elements are visible
			textRefElements.forEach((element) => {
				const isHalfVisible = this.checkVisibility(element);
				if (
					isHalfVisible &&
					this.currentTextRef != element.dataset.ref
				) {
					this.currentTextRef = element.dataset.ref;
				}
			});
			illustrationRefElements.forEach((element) => {
				const isHalfVisible = this.checkVisibility(element);
				if (
					isHalfVisible &&
					this.currentIllustrationRef !=
						element.getAttribute("data-ref-illustration")
				) {
					this.currentIllustrationRef = element.getAttribute(
						"data-ref-illustration"
					);
				}
			});
		},
		checkVisibility(element) {
			// check if the element fills more than half of the viewport
			const rect = element.getBoundingClientRect();
			const windowHeight =
				window.innerHeight || document.documentElement.clientHeight;
			const isHalfVisible =
				rect.top < windowHeight / 2 && rect.bottom > windowHeight / 2;
			return isHalfVisible;
		},
	};
}
