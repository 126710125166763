export default function contactForm() {
	return {
		success: false,
		submitText: "Send Message",
		error: "",
		subject: "",
		deliveryDate: "",
		fromName: "",
		submitForm() {
			const form = this.$root.querySelector("form");
			if (this.$validate.isComplete(form)) {
				const formData = new FormData(form);
				formData.append("action", "contact-form/send");
				this.changeText("Sending...");
				fetch("/", {
					method: "POST",
					body: formData,
					headers: {
						Accept: "application/json",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.modelName == "submission") {
							this.error = "";
							this.changeText("Sent");
							setTimeout(() => {
								this.changeSuccess(true);
							}, 1000);
						} else {
							this.changeSuccess(false);
							this.error = data.message;
							this.changeText("Failed. Try again.");
						}
					});
			}
		},
		changeText(newText) {
			if (!document.startViewTransition) {
				this.submitText = newText;
				return;
			}
			document.startViewTransition(() => {
				this.submitText = newText;
			});
		},
		changeSuccess(state) {
			if (!document.startViewTransition) {
				this.success = state;
				if (state === true) {
					setTimeout(() => {
						this.scrollRootIntoView();
					}, 500);
				}
				return;
			}
			document.startViewTransition(() => {
				this.success = state;
				if (state === true) {
					setTimeout(() => {
						this.scrollRootIntoView();
					}, 500);
				}
			});
		},
		scrollRootIntoView() {
			this.$root.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		},
	};
}
